* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.chartBox {
  width: 36.46vw;
  /* padding: 10px; */

  background: white;
  display: flex;
}

.colSmall {
  width: 1.82vw;
  height: 18.52vh;
}

.colLarge {
  max-width: 36.46vw;
  overflow-x: scroll;
}

.box {
  width: calc(36.46vw - 1.82vw);
  height: 18.52vh;
}

/* Webkit (Chrome, Safari) */
.colLarge::-webkit-scrollbar {
  height: 4px; /* Thin by default */
}

.colLarge::-webkit-scrollbar-thumb {
  background: #aaa; /* Default scrollbar color */
  border-radius: 5px;
}

.colLarge::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
  height: 10px;
}
