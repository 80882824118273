* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.chartBoxReport {
  width: 600px;
  /* padding: 10px; */

  background: white;
  display: flex;
}

.colSmallReport {
  width: 35px;
  height: 300px;
}

.colLargeReport {
  max-width: 600px;
  overflow-x: scroll;
}

.boxReport {
  width: calc(600px - 35px);
  height: 300px;
}

/* Webkit (Chrome, Safari) */
.colLargeReport::-webkit-scrollbar {
  height: 4px; /* Thin by default */
}

.colLargeReport::-webkit-scrollbar-thumb {
  background: #aaa; /* Default scrollbar color */
  border-radius: 5px;
}

.colLargeReport::-webkit-scrollbar-thumb:hover {
  background: #555; /* Darker color on hover */
  height: 10px;
}
